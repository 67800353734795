import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '../views/Login.vue'
import Admin from '../views/Admin.vue'

const routes = [
  {path: '/',redirect: '/login'},
  {path: '/login',name: 'login',component: Login},
  {path: '/admin',name: 'admin',component: Admin}
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
