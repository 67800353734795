import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { onMounted, getCurrentInstance } from 'vue';
import $ from 'jquery';
export default {
  __name: 'App',
  setup(__props) {
    const store = useStore();
    const router = useRouter();
    onMounted(() => {});
    const {
      proxy
    } = getCurrentInstance();
    const verification = () => {
      proxy.$http.post('verification', {}).then(res => {
        if (res.state === 'ok') {
          store.state.userType = res.user_type;
          router.push('admin');
        }
      }).catch(err => {
        console.log(err);
      });
    };
    const bgcUpdate = page => {
      if (page == 'login') {
        $('html').removeClass('bgcAdmin').addClass('bgcLogin');
      } else {
        $('html').removeClass('bgcLogin').addClass('bgcAdmin');
      }
    };
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createBlock(_component_router_view, {
        onVerification: verification,
        onBgcUpdate: bgcUpdate
      });
    };
  }
};