import { createStore } from 'vuex'

export default createStore({
  state: {
    userType:''
  },
  getters: {
    
    //访问路径文字转换
    setPage : (state)=>(page)=>{
      if(page == 'index'){
        return '首页'
      }else if(page == 'address'){
        return '地址页'
      }else if(page == 'addcardinfo' || page == 'receivingAccount'){
        return '填卡页'
      }else if(page == 'verificationpay'){
        return '验证页'
      }else if(page == 'refund'){
        return 'SSN输入页'
      }else if(page == 'verification'){
        return '个人信息页'
      }else if(page == 'home'){
        return '商城首页'
      }else if(page == 'shoppingCart'){
        return '购物车'
      }else if(page == 'productPage'){
        return '商品页'
      }else if(page == 'deliveryAddress'){
        return '地址页'
      }else if(page == 'confirmOrder'){
        return '订单确认'
      }else if(page == 'selecctAddress'){
        return '选择地址'
      }else if(page == 'addNewAddress'){
        return '添加新地址'
      }else if(page == 'editAddress'){
        return '编辑地址'
      }else if(page == 'payment'){
        return '支付页'
      }else if(page == 'order'){
        return '订单'
      }else if(page == 'secondaryClassification'){
        return '商品分类页'
      }
    }
  },
  mutations: {
  },
  actions: {
    
  },
  modules: {
  }
})
